import {
  Routes,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideExperimentalZonelessChangeDetection, inject, provideEnvironmentInitializer } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { appInitGapi, RefreshGapiTokenService } from '@dis/auth';
import { provideFirebase } from './firebase/firebase.config';
import { environment } from '../../environments/environment';

import { GAPIService } from '@dis/gapi';
import { provideNgxsCore } from './ngxs/ngxs.core';
import { provideMaterialCore } from './material/material.core';

export interface CoreOptions {
  routes: Routes;
}

export function provideCore({ routes }: CoreOptions) {
  return [
    provideExperimentalZonelessChangeDetection(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      }),
    ),

    // other 3rd party libraries providers like NgRx, provideStore()
    provideFirebase(environment),
    provideNgxsCore(),
    provideMaterialCore(),

    // other application specific providers and setup


    // perform initialization, has to be last
    // {
    //   provide: ENVIRONMENT_INITIALIZER,
    //   multi: true,
    //   useValue() {
    //     // add init logic here...
    //     // kickstart processes, trigger initial requests or actions, ...
    //   },
    // },
    provideEnvironmentInitializer(() => {
        const initializerFn = (appInitGapi)(inject(GAPIService), inject(RefreshGapiTokenService));
        return initializerFn();
      }),
  ];
}
