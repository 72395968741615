import { ChangeDetectionStrategy, Component, OnInit, inject, isDevMode } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { getAllChanges, RemoteConfig } from '@angular/fire/remote-config';
import { traceUntilFirst } from '@angular/fire/performance';

import { EMPTY, tap } from 'rxjs';

import { NgswService } from './core/services/ngsw.service';
// import { FcmService } from './core/services/fcm.service';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';

@Component({
    selector: 'app-root',
    imports: [MainLayoutComponent],
    template: `<app-main-layout />`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'Job & Tool Management System';

  // fcmService = inject(FcmService);
  iconRegistry = inject(MatIconRegistry);
  ngswService = inject(NgswService);

  private readonly remoteConfig = inject(RemoteConfig);
  protected readonly config$ = this.remoteConfig ?
    getAllChanges(this.remoteConfig)
    .pipe(
      traceUntilFirst('remote-config'),
      tap(changes => console.log('remote-config', changes)),
    ) :
    EMPTY;

  ngOnInit(): void {
    /**
     * Put this here until understand how to implement in material core as a provider
     */
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    // Angular Serviceworker initilization;
    if (!isDevMode()) {
      this.ngswService.initSwUpdate();
      this.ngswService.initSwPush();
    }
  }
}
