@let claims = claims$();
@if (claims) {
<mat-tree #navTree [dataSource]="dataSource" [childrenAccessor]="childrenAccessor">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
    [routerLink]="transformRouteLink(node, claims?.user_id)" routerLinkActive="active">
    <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
      {{ node.icon }}
    </mat-icon>
    {{node.name}}
  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle matTreeNodePadding
    routerLinkActive="active">
    <mat-icon class="type-icon" [attr.aria-label]="node.icon + 'icon'" matListIcon>
      {{ node.icon }}
    </mat-icon>
    {{node.name}}
  </mat-tree-node>

</mat-tree>
} @else {
<mat-nav-list>
  <a mat-list-item routerLink="auth/signin" routerLinkActive="active">Login
    <mat-icon matListIcon>login</mat-icon>
  </a>
</mat-nav-list>
}

<ng-template #signin>
  <mat-nav-list>
    <a mat-list-item routerLink="auth/signin" routerLinkActive="active">Login
      <mat-icon matListIcon>login</mat-icon>
    </a>
  </mat-nav-list>
</ng-template>
