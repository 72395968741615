import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Observable } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { select } from '@ngxs/store';

import { AuthState } from '@dis/auth';
import { LayoutState } from '@dis/shared';

import { SidenavService } from '../sidenav/sidenav.service';

import { ActivePressureTestComponent } from '../../../ui/active-pressure-test/active-pressure-test.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatProgressBarModule,
        MatToolbarModule,
        RouterLink,
        ActivePressureTestComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {

  isHandset = select(LayoutState.isHandset);

  pressureTest$: Observable<any>;

  sidenavService = inject(SidenavService);

  user = select(AuthState.claims);

}
