import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTreeModule } from '@angular/material/tree';
import { select } from '@ngxs/store';
import { AuthState } from '@dis/auth';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { appRoutes } from './app-route-data';

/** Route node data with possible child nodes. */
export interface RouteTreeNode {
  name: string;
  acl?: string[];
  project?: string;
  icon?: string;
  route?: string[];
  children?: RouteTreeNode[];
}

/**
 * Flattened tree node that has been created from a RouteNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode extends RouteTreeNode {
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.css'],
  imports: [
    MatIconModule,
    MatListModule,
    MatTreeModule,
    RouterLinkActive,
    RouterLink,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavTreeComponent {
  dataSource = appRoutes;
  claims$ = select(AuthState.claims);

  childrenAccessor = (node: RouteTreeNode) => node.children ?? [];

  /** Get whether the node has children or not. */
  hasChild = (_: number, node: RouteTreeNode) => !!node.children && node.children.length > 0;

  transformRouteLink(node: RouteTreeNode, user_id?: string) {
    const { route } = node;
    if (route) {
      return route[0] === 'user'
        ? ['user', user_id]
        : route.length > 1
          ? [route[0], route[1]]
          : [route[0]]
    }
    return null;
  }
}
